$.validator.setDefaults({
    errorElement: "span",
    errorClass: "text-danger field-validation-error",
    validClass: "valid",
    errorPlacement: function (error, element) {
        var placement = $(element).data("error");
        if (placement) {
            $(placement).append(error);
        } else {
            if (element.is("select")) {
                let parent = element.parent();
                if (parent.hasClass("bootstrap-select")) {
                    if (parent.parent().hasClass("input-group")) {
                        error.insertAfter(parent.parent());
                    } else {
                        error.insertAfter(parent);
                    }
                    return;
                }
            } else {
                if (element.is(":radio")) {
                    let parent = element.parents("ul");
                    if (parent.length != 0) {
                        error.insertAfter(parent);
                        return;
                    }
                }

                if (element.is(":checkbox")) {
                    let parent = element.parent();
                    if (parent.length != 0) {
                        error.insertAfter(parent);
                        return;
                    }
                }

                let item = element.next();
                if (item.length > 0) {
                    if (
                        item.hasClass("bs-placeholder") ||
                        item.hasClass("dropdown-toggle")
                    ) {
                        element = item;
                    }
                    if (item.hasClass("custom-control-label")) {
                        element = item.parent().parent();
                    }
                    if (item.hasClass("input-group-append")) {
                        element = item.parent();
                    }
                } else {
                    item = element.prev();
                    if (item.hasClass("input-group-prepend")) {
                        element = item.parent();
                    }
                }
            }
            error.insertAfter(element);
        }
    },
});